var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", [_c("h1", [_vm._v("Contracten")])]),
          _c(
            "b-col",
            { staticClass: "text-right", attrs: { cols: "4" } },
            [
              _c(
                "label",
                { staticClass: "mr-4", attrs: { for: "text-search" } },
                [_vm._v("Zoeken")]
              ),
              _c("b-form-input", {
                staticClass: "inline mr-4",
                attrs: { id: "text-search", debounce: "800" },
                model: {
                  value: _vm.filter.q,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "q", $$v)
                  },
                  expression: "filter.q"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            [
              _c(
                "i",
                {
                  staticStyle: {
                    "font-weight": "bold",
                    "font-size": "18px",
                    color: "#bf0000"
                  }
                },
                [_vm._v("»")]
              ),
              _c(
                "router-link",
                {
                  staticClass: "ml-2",
                  attrs: {
                    to: { name: "contract-detail-edit", params: { id: "new" } }
                  }
                },
                [_vm._v("Nieuw contract aanmaken...")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            [
              _c("rest-table", {
                attrs: {
                  endpoint: "contracts",
                  fields: _vm.fields,
                  filter: _vm.filter
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(Name)",
                    fn: function(data) {
                      return [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "contract-detail",
                                params: { id: data.item.ContractID }
                              }
                            }
                          },
                          [_c("strong", [_vm._v(_vm._s(data.item.Name))])]
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(Mailreport)",
                    fn: function(data) {
                      return [
                        _c(
                          "a",
                          { attrs: { href: "mailto:" + data.item.Mailreport } },
                          [_vm._v(_vm._s(data.item.Mailreport))]
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(actions)",
                    fn: function(data) {
                      return [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "contract-detail-edit",
                                params: { id: data.item.ContractID }
                              }
                            }
                          },
                          [_c("b-icon-pencil-square")],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }