<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col>
        <h1>Contracten</h1>
      </b-col>

      <b-col cols="4" class="text-right">
        <label for="text-search" class="mr-4">Zoeken</label>
        <b-form-input id="text-search" class="inline mr-4" v-model="filter.q" debounce="800" /> 
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <i style="font-weight:bold; font-size:18px ;color:#bf0000">»</i>
        <router-link :to="{ name: 'contract-detail-edit', params: { id: 'new' } }" class="ml-2">Nieuw contract aanmaken...</router-link>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <rest-table
            endpoint="contracts"
            :fields="fields"
            :filter="filter">

          <template v-slot:cell(Name)="data">
            <router-link :to="{ name: 'contract-detail', params: { id: data.item.ContractID } }">
              <strong>{{ data.item.Name }}</strong>
            </router-link>
          </template>

          <template v-slot:cell(Mailreport)="data">
              <a :href="'mailto:' + data.item.Mailreport">{{ data.item.Mailreport }}</a>
          </template>

          <template v-slot:cell(actions)="data">
            <router-link :to="{ name: 'contract-detail-edit', params: { id: data.item.ContractID } }">
              <b-icon-pencil-square></b-icon-pencil-square>
            </router-link>
          </template>
        </rest-table>
      </b-col>
    </b-row>  
  </b-container>
</template>

<script>
  import RestTable from '@/components/RestTable.vue'

  export default {
    name: 'Contracts',
    components: {
      RestTable,
    },
    data () {
      return {
        filter: {
          q: ''
        },
        fields: [
          {
            key: 'idx',
            label: '#'
          },
          {
            key: 'Name',
            label: 'Naam',
            sortable: true,
          },
          {
            key: 'Company',
            label: 'Bedrijfsnaam',
            sortable: true,
          },
          {
            key: 'Mailreport',
            label: 'E-mail',
            sortable: true,
          },
          {
            key: 'Tel',
            label: 'Telefoon',
            sortable: true,
          },
          {
            key: 'Status',
            label: 'Status',
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Acties',
            class: 'link-icons',
          },
        ],
      }
    },
    mounted () {
      this.$root.$data.crumbs = [
        {to: { name: 'contracts' }, text: 'Contracten'}
      ]
    }
  }
</script>

<style scoped>
  label {
    font-weight: bold;
  }

  v-select {
    height: calc(1.5em + 0.75rem + 2px);
  }

  .inline {
    width: auto;
    display: inline-block;
  }
</style>